import React from "react";

import { IconButton, Tooltip } from "@mui/material";





export const ActionButton = (props)=>{
    return(
        <Tooltip title={props.Title}>
            <IconButton onClick={props.OnClick}>{props.Icon}</IconButton>
        </Tooltip>
    )
}