import React from 'react';
import axios from 'axios';
import { API } from '../classes/config/controller';
import { Button } from '@mui/material';

class DownloadDocxButton extends React.Component {
  convertAndDownload = async () => {
    const { htmlContent, docName } = this.props;

    try {
      const response = await axios.post(API+'convert', {
        htmlContent: htmlContent,
        docName: docName
      }, {
        responseType: 'blob',
      });

      const url = URL.createObjectURL(response.data);
      const link = document.createElement('a');
      link.href = url;
      link.download = `${docName}.docx`;
      link.click();
      URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Conversion error:', error);
    }
  };

  render() {
    return (
      <Button variant="contained"  sx={{marginLeft:'10px', float:'right'}} onClick={this.convertAndDownload}>
        <b>Download DOCX</b>
      </Button>
    );
  }
}

export default DownloadDocxButton;
