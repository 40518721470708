import React from "react";
export const Circle = (props)=>{

    return(
        <div style={ {
              
               
                display: "inline-block",
                backgroundColor: props.Color,
                borderRadius: "50%",
                width: 30,
                textAlign:'center',
                color:'white',
                paddingTop:'1px',
                height: 30}}>{props.Title}</div>
    )
};