import React from 'react';
import axios from 'axios';
import { API } from '../classes/config/controller';
import { Button } from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import { ActionButton } from './ActionButton';

class DownloadDocxIcon extends React.Component {
  convertAndDownload = async (props) => {
    const { htmlContent, docName } = this.props;


    try {
      const response = await axios.post(API+'convert', {
        htmlContent: htmlContent,
        docName: docName
      }, {
        responseType: 'blob',
      });

      const url = URL.createObjectURL(response.data);
      const link = document.createElement('a');
      link.href = url;
      link.download = `${docName}.docx`;
      link.click();
      URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Conversion error:', error);
    }
  };

  render() {
    return (
      <ActionButton OnClick={this.convertAndDownload} Title="Download" Icon={<DownloadIcon htmlColor="#0098D9"/>}/>
      // <Button variant="contained"  sx={{marginLeft:'10px', float:'right'}} onClick={this.convertAndDownload}>
      //   <b>Download as DOCX</b>
      // </Button>
    );
  }
}

export default DownloadDocxIcon;
