import { Box, CircularProgress, Modal, Skeleton, Typography } from "@mui/material";
import React, { useEffect } from "react";


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '60%',
    textAlign:'center',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

export const LoadingUser = ()=>{
    const [open, setOpen] = React.useState(true);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
  
    return (
        <Modal
  open={open}
//   onClose={handleClose}
//   aria-labelledby="modal-modal-title"
//   aria-describedby="modal-modal-description"
>
  <Box sx={style}>
    <Typography id="modal-modal-title" variant="button" sx={{fontSize:'24px'}}>
      <b>LOADING USER DATA</b>
    </Typography>

    <Box sx={{textAlign:'center'}}>
    <br></br>
    <CircularProgress color="secondary" />
    <br></br>
   <br></br>
<Skeleton sx={{backgroundColor:'#9755EA77'}} variant="rectangular" width={'100%'} height={10} />
<br></br>
<Skeleton sx={{backgroundColor:'#9755EA77'}} variant="rectangular" width={'100%'} height={10} />
<br></br>
<Skeleton sx={{backgroundColor:'#9755EA77'}} variant="rectangular" width={'100%'} height={10} />
<br></br>
</Box>
{/* <Skeleton variant="rounded" width={210} height={60} /> */}
  </Box>
</Modal>
    )
}