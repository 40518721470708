import React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import AddIcon from '@mui/icons-material/Add';

import '../assets/css/style.css';
import { Form, InputGroup } from 'react-bootstrap';
import { Tooltip } from '@mui/material';
const style = {
  position: 'absolute',
  textAlign:'start',
  top: '50%',
  left: '50%',
  height:'auto',
  transform: 'translate(-50%, -50%)',
  maxWidth:600,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

export default function AddContentModal  (props) {
  
  
  return (
    <div>
     
      <Modal
        open={props.Open}
        onClose={props.CloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
        
        <Typography sx={{fontSize:'16px'}} variant='button'><b>Add {props.SectionName}</b></Typography>
        <br></br>
        <p></p>
        <Typography sx={{fontSize:'14px', marginTop:'10px !important'}} variant='caption'>Type the content you would like to add to this section then click <b>'+'</b>. After you have enter all of the custom lines for this section then you can click the <b>ADD CONTENT</b> button to add it to the document.</Typography>
        <InputGroup className="mb-3 mt-3">
        <Form.Control
        value={props.ContentValue}
        style={{width:'300px'}}
          placeholder="Type your question here"
         onChange={props.ChangeContentValue}
          aria-describedby="basic-addon2"
        />
        <Tooltip title="Add another question">
        <Button onClick={props.ClickAddContentToArray} variant="contained" id="button-addon2">
          <AddIcon/>
        </Button>
        </Tooltip>
      </InputGroup>
<ul>
  {props.ContentArray.map(i=>{
      return (<li>{i}</li>)
    })}
    </ul>
      <Box  sx={{textAlign:'end'}}>

  
     
        <Button onClick={props.AddContentToSection} variant='contained'><b>Add Content</b></Button>
      </Box>
      
       </Box>

      </Modal>
    </div>
  );
}