import Docxtemplater from "docxtemplater";
import PizZip from "pizzip";
import PizZipUtils from "pizzip/utils/index.js";
import {saveAs} from "file-saver";
import document from './document/job-post-template.docx'
import documentSalary from './document/job-post-template-s.docx'
function loadFile(url, callback) {
    PizZipUtils.getBinaryContent(url, callback);
}

export const GenerateDocument = (object, docname, salary) => {
    let finalObject = {
        jobTitle: object.jobTitle,
        jobSummary: object.jobSummary,
        salary: salary,
        responsibilities: object.responsibilities.map(i=>{return({value:i})}),
        qualifications: object.qualifications.map(i=>{return({value:i})}),
        requirements: object.requirements.map(i=>{return({value:i})}),
        preferredSkills: object.preferredSkills.map(i=>{return({value:i})}),
        educationAndExperience: object.educationAndExperience.map(i=>{return({value:i})}),
        benefits: object.benefits.map(i=>{return({value:i})}),
        equalOpportunity: object.equalOpportunity,
        adaCompliant: object.adaCompliant,
        conclusion: object.conclusion

    }

    
   
    console.log(finalObject)
    loadFile(
        salary === '' ?document : documentSalary,
        function (error, content) {
            if (error) {
                throw error;
            }
            const zip = new PizZip(content);
            const doc = new Docxtemplater(zip, {
                paragraphLoop: true,
                linebreaks: true,
            });

            // render the document (replace all occurences of {first_name} by John, {last_name} by Doe, ...)
            doc.render(finalObject);
            const blob = doc.getZip().generate({
                type: "blob",
                mimeType:
                    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
            }); //Output the document using Data-URI
            saveAs(blob, `${docname}.docx`);
        }
    );
};