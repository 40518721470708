
import React, {useState, useEffect} from "react";
import { Badge, Form, Stack } from "react-bootstrap";
import { Box, Button, Divider, IconButton, Paper, TextField, Typography, Modal, Switch, Fade } from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import InlineEditor from '@ckeditor/ckeditor5-build-inline';
import DownloadDocxButton from "../components/DownloadDocx";

import { Circle } from "../components/Circle";
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import DeleteIcon from '@mui/icons-material/Delete';
import LoadingModal from "../components/LoadingModal";
import { GenerateDocument } from "../classes/GenerateDocx";
import { API, SaveJobPost } from "../classes/config/controller";
import { v4 as uuidv4 } from 'uuid';
import { ActionButton } from "../components/ActionButton";
import '../assets/css/style.css'
import AddContentModal from "../components/AddContentModal";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import axios from "axios";

export const Writer = (props)=>{
const [content, setContent] = useState('')
const [loading, setLoading] = useState(true)
const [catArray, setCatArray] = useState([])
const [jobPostData, setJobPostData] = useState()
const [number, setNumber] = useState(0)
const [job, setJob] = useState('')
const [company, setCompany] = useState('')
const [salary, setSalary] = useState('')
const [sectionName, setSectionName] = useState('')
const [showSalary, setShowSalary] = useState(false)
const [update, setUpdate] = useState(false)
const [openModal, setOpenModal] = useState(false);
const [showDelete, setShowDelete] = useState(false);
const [contentToAdd, setContentToAdd] = useState('')
const[contentArray, setContentArray] = useState([])
const [openAddContent, setOpenAddContent] = useState(false)
const [editorContent, setEditorContent] = useState('');
const [documentTitle, setDocumentTitle] = useState('')
const[pt1, setPt1] = useState('')
const[pt2, setPt2] = useState('')
useEffect(()=>{
  
    
    if(pt1 !== '' && pt2 !== ''){
        setEditorContent(pt1 + pt2)
        setOpenModal(false)
        setLoading(false)
    }
},[pt1])

useEffect(()=>{
 
    if(pt1 !== '' && pt2 !== ''){
        setEditorContent(pt1 + pt2)
        setOpenModal(false)
        setLoading(false)
    }
},[pt2])


const handleEditorChange = (event, editor) => {
    const data = editor.getData();
    setEditorContent(data);
  };

const AddRemoveCategory = (a)=>{

    if(catArray.includes(a)){
        let arr = catArray.filter(i=>{
            return (i !== a)
        })

        setCatArray(arr)
    } else {
        catArray.push(a)
    }
    console.log(catArray)

}
const handleBack = ()=>{
const check = window.confirm('If you choose to go back your job post will not be saved. Are you sure you would like to do this?')
if(check){
    
setLoading(true);
setCatArray([]);
setNumber(0);
setJob('');

}
}

const createQuestionairre = ()=>{

   setOpenModal(true)
        const getData = async ()=>{
          let prompt = `Act as a Human Resources Professional. In under 250 words, write a job post for an ${job}. Return with simple html code inside a div make sure all the headings are p tags with font-size of 18px and bold. Use li tags when needed.
${showSalary ? 'Above the Job Summary section include salary of:'+ salary :''}
Here is a basic html format of what should be included:
    
          'Company',
          'Job Title',
          ${showSalary ? 'salary'+ salary:''}
          'Job Summary',
          'responsibilities',
          'qualifications',
          'requirements',

       
          Include the company name: ${company}
Make sure and write content for each heading based on recent data for that position.`
           
                    const response = await axios.post(API+'api/create-document',{request:prompt})
                 if(response != 'error'){
                    console.log(response.data)
            
                  
                    try {
                        const result = response.data;
                      
                     
                        setPt1(result)
              
                    
                        setEditorContent(`${pt1} ${pt2}`)
                        console.log(editorContent)
                      
                
                 
                    } catch (error) {
                        setOpenModal(false)
                    console.error('Error parsing JSON response:', error);
                    alert('Something went wrong please try again.')
                    return;
                    }
                  
                //     let arr = JSON.parse(response.text)

                   
            
                  

                 } else{
                    alert('Something went wrong please try again.')
                 }
                }

                const getDataPt2 = async ()=>{
                    let prompt = `Act as a Human Resources Professional. In under 250 words, write a job post for an ${job}. Return with simple html code inside a div make sure all the headings are p tags with font-size of 18px and bold. Use li tags when needed.
          
          Here is a basic html format of what should be included:
              
          'preferred Skills',
          'education And Experience',
          'benefits',
          'equal Opportunity',
          'ada Compliant',
          'conclusion'
                    
                Do not include the job title or description. ONLY include these details.
          Make sure and write content for each heading based on recent data for that position.`
                     
                              const response = await axios.post(API+'api/create-document',{request:prompt})
                           if(response != 'error'){
                              console.log(response.data)
                      
                            
                              try {
                                  const result = response.data;
                                
                               
                                  setPt2(result)
                        
                               
                                    setEditorContent(`${pt1} ${pt2}`)
                                    console.log(editorContent)
                               
                                
                           
                           
                               
          
                           
                              } catch (error) {
                                  setOpenModal(false)
                              console.error('Error parsing JSON response:', error);
                              alert('Something went wrong please try again.')
                              return;
                              }
                            
                          //     let arr = JSON.parse(response.text)
          
                             
                      
                            
          
                           } else{
                              alert('Something went wrong please try again.')
                           }
                          }
                getData()
                getDataPt2()
    
    
}
if(loading) {
    return (
        <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>

        <div style={{padding:'20px'}}>

            <Box>

            <Typography variant="button" sx={{fontSize:'16px'}}><b><Circle Title="1" Color="#0172C0"/>  Job title and Company Name:</b></Typography><br/>
            <Typography sx={{marginTop:'10px'}} variant="subtitle2">Please enter the job title and the company you would like to make a job post for.</Typography>
         <br></br>
         <Container>
         <Row xs={1} md={2}>
        <Col className="mt-1">
        <Form.Control 
        placeholder="Job Title"
        onChange={(e)=> setJob(e.target.value)} 
            />
            </Col>
        <Col className="mt-1">
        <Form.Control 
            placeholder="Company Name"
            onChange={(e)=> setCompany(e.target.value)} 
            />
           
        </Col>
      </Row>
      <Fade in={showSalary}>
            <Row style={{display:showSalary ? 'block':'none'}} className="mt-3"><Col>  
            <Form.Control 
            placeholder="Add Salary"
            onChange={(e)=> setSalary(e.target.value)} 
            />
            </Col>
            </Row>
    </Fade>
      <Row className="mt-3"><Col> <Form.Label>Add Salary to Job Post?</Form.Label>
      <Switch defaultChecked={showSalary} onChange={()=>{setShowSalary(!showSalary)}}/>
      </Col></Row>
      </Container>
           


            </Box>
            
            <br></br>
            <div style={{textAlign:'right'}}>
            <Button sx={{marginTop:1, backgroundColor:'#0172C0'}} variant="contained" onClick={createQuestionairre}><b>Generate Job Post</b></Button>
            </div> 
          <LoadingModal
          Open={openModal}
         
          />
       
        </div>
        </Paper>
    )
} else {
    
    return (
        <div>

            <Box sx={{marginBottom:'10px'}}>
            <Button variant="contained" onClick={handleBack}><ArrowBackIosNewIcon/> <b>BACK</b></Button>

            <DownloadDocxButton htmlContent={editorContent} docName={job} />

            {/* <Button onClick={()=>{
                 let object = {
                    id:uuidv4(),
                    dateCreated: new Date(),
                    jobName: job,
                    content: editorContent
                    
                }
                SaveJobPost(object);
                props.Action()
                
                }} sx={{marginLeft:'10px', float:'right', display:showDelete ? 'none':'inline'}}  variant="contained"><b>SAVE</b></Button> */}
            </Box>
        <Paper sx={{ p: 5, display: 'flex', flexDirection: 'column' }}>
              <div>
      <CKEditor
        editor={InlineEditor}
     
        data={editorContent}
        onChange={handleEditorChange}
      />
    
    </div>
    </Paper>
    <AddContentModal
    Open={openAddContent}
    CloseModal={()=>setOpenAddContent(false)}
    SectionName={sectionName}
    ContentValue={contentToAdd}
    ChangeContentValue={(e)=>setContentToAdd(e.target.value)}
    ContentArray={contentArray}
    ClickAddContentToArray={()=>{
        contentArray.push(contentToAdd);
        setContentToAdd('');
    }}
    AddContentToSection={()=>{
        if(sectionName === 'benefits'){
            contentArray.forEach(i=>{
                jobPostData.benefits.push(i);
            })
        }

        if(sectionName === 'responsibilities'){
            contentArray.forEach(i=>{
                jobPostData.responsibilities.push(i);
            })
        }

        if(sectionName === 'education and experience'){
            contentArray.forEach(i=>{
                jobPostData.educationAndExperience.push(i);
            })
        }

        if(sectionName === 'qualifications'){
            contentArray.forEach(i=>{
                jobPostData.qualifications.push(i);
            })
        }

        if(sectionName === 'requirements'){
            contentArray.forEach(i=>{
                jobPostData.requirements.push(i);
            })
        }

        if(sectionName === 'preferred skills'){
            contentArray.forEach(i=>{
                jobPostData.preferredSkills.push(i);
            })
        }

        setContentArray([])
        setOpenAddContent(false)
    }}

    />
    </div>
)

}
}